import * as XLSX from "xlsx";
import {
  isScoreValid,
  isStatusValid,
  isTrafficValid,
  isCountryValid,
  parseCategories,
  isAgeValid,
  isUrlValid,
  isExtensionValid,
} from "../../utils/validation";

const fileTypeValidation = (type) => {
  return (
    type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
    type === "text/csv" ||
    type === "application/vnd.ms-excel"
  );
};

export const parseFile = async (info) => {
  return new Promise((resolve, rejected) => {
    if (info.file.status === "uploading") {
      // validate file type
      if (fileTypeValidation(info.file.type)) {
        let fileReader = new FileReader();
        fileReader.onload = async (event) => {
          let workbook = XLSX.read(event.target.result, { type: "binary" });
          let fileData = [];
          //parse each sheet of excel to collect the data
          workbook.SheetNames.map((sheet) => {
            let rowObject = XLSX.utils.sheet_to_row_object_array(
              workbook.Sheets[sheet]
            );
            fileData = [...fileData, ...rowObject];
          });

          resolve({ fileData, originalFile: info.file.originFileObj });
        };
        fileReader.readAsBinaryString(info.file.originFileObj);
      } else
        rejected({
          msgType: "ERROR",
          title: "Invalid File Format",
          message: `The file format is not supported.`,
        });
    }
  }).then((data) => data);
};

const mandtoryFieldsCheck = (fileHeaders, index) => {
  let errorField = false;

  if (!fileHeaders["url"]) errorField = "url";
  if (!fileHeaders["extension"]) errorField = "extension";
  if (!fileHeaders["country"]) errorField = "country";
  if (!Number.isInteger(fileHeaders["da"])) errorField = "da";
  if (!Number.isInteger(fileHeaders["dr"])) errorField = "dr";
  if (!Number.isInteger(fileHeaders["traffic"])) errorField = "traffic";
  if (!Number.isInteger(fileHeaders["links"])) errorField = "links";
  if (!fileHeaders["categories"]) errorField = "categories";
  if (!fileHeaders["age"]) errorField = "age";
  if (!Number.isInteger(fileHeaders["spam"])) errorField = "spam";
  if (!fileHeaders["status"]) errorField = "status";
  if (errorField) throw { row: index + 2, field: errorField };
  else return true;
};

export const validateFormat = (fileData) => {
  return new Promise((resolve, rejected) => {
    //check if the file is empty
    if (fileData.length < 1)
      rejected({
        msgType: "error",
        title: "Empty File!",
        message: `Uploaded file is empty.`,
      });

    try {
      let checkResults = fileData.every((row, index) =>
        mandtoryFieldsCheck(row, index)
      );
      if (checkResults) resolve();
    } catch (e) {
      rejected({
        msgType: "error",
        title: `[Row: ${e.row}] [Field: ${e.field}] Mandatory Fields Missing!`,
        message: `The import is not successful. Please check the source file for formatting issues. Following fields are mandatory for each website.  `,
      });
    }
  });
};

export const validateValues = (fileData) => {
  const tempFileData = JSON.parse(JSON.stringify(fileData));
  return new Promise((resolve, rejected) => {
    let error = {};
    let validationResult = tempFileData.every((website, index) => {
      //validate url
      if (!isUrlValid(website.url)) {
        error = {
          row: index + 2,
          field: "url",
          message: `'${website.url}' is not a valid URL.`,
        };
        return false;
      }

      try {
        new URL(website.url);
      } catch (e) {
        error = {
          row: index + 2,
          field: "url",
          message: `'${website.url}' is not a valid URL.`,
        };
        return false;
      }

      //validate Extension
      if (!isExtensionValid(website)) {
        error = {
          row: index + 2,
          field: "extension",
          message: `'${website.extension}'is not a valid extension. Value for this field should start with period (.) and must include in url.`,
        };
        return false;
      }

      //validate DA
      if (!isScoreValid(website.da)) {
        error = {
          row: index + 2,
          field: "da",
          message: `'${website.da}'is not a valid score. Value for this field should range in between 0-100.`,
        };
        return false;
      }

      //validate DR
      if (!isScoreValid(website.dr)) {
        error = {
          row: index + 2,
          field: "dr",
          message: `'${website.dr}'is not a valid score. Value for this field should range in between 0-100.`,
        };
        return false;
      }

      //validate Spam Score
      if (!isScoreValid(website.spam)) {
        error = {
          row: index + 2,
          field: "spam",
          message: `'${website.spam}'is not a valid score. Value for this field should range in between 0-100.`,
        };
        return false;
      }

      //validate Links
      if (!isScoreValid(website.links)) {
        error = {
          row: index + 2,
          field: "links",
          message: `'${website.da}'is not a valid score. Value for this field should range in between 0-100.`,
        };
        return false;
      }

      //validate Age
      if (!isAgeValid(website)) {
        error = {
          row: index + 2,
          field: "age",
          message: `'${website.age}'is not a valid age. Value for this field should follow the format YYYY-MM-DD.`,
        };
        return false;
      }

      //validate Traffic
      if (!isTrafficValid(website.traffic)) {
        error = {
          row: index + 2,
          field: "traffic",
          message: `'${website.traffic}'is not a valid value. Value for this field should range in between 0-Infinity.`,
        };
        return false;
      }
      //validate Traffic
      if (!isStatusValid(website)) {
        error = {
          row: index + 2,
          field: "status",
          message: `'${website.status}'is not a valid status. Value for this field could be either Blocked or Active.`,
        };
        return false;
      }

      //validate country
      if (!isCountryValid(website)) {
        error = {
          row: index + 2,
          field: "country",
          message: `'${website.country}'is not a valid country name. Please check available countries in import guideline.`,
        };
        return false;
      }

      //validate categories
      if (!parseCategories(website)) {
        error = {
          row: index + 2,
          field: "categories",
          message: `'${website.categories}'is not a valid category format. Make sure you're following the specified format: "category_name[cost,retail_price],category_name[cost,retail_price],.." .Validate 'category_name from the available categories list in import guideline.`,
        };
        return false;
      }

      return true;
      //
    });

    if (validationResult) resolve(tempFileData);
    else
      rejected({
        msgType: "error",
        title: `[Row: ${error.row}] [Field: ${error.field}]`,
        message: error.message,
      });
  });
};

export const groupOldNew = async (fileData, websites) => {
  let websitesObj = {};
  websites.map((e) => {
    websitesObj[e.url] = e;
  });
  let new_websites = [];
  let old_websites = [];
  await fileData.map((website) => {
    let url = new URL(website.url);
    if (websitesObj[url.hostname])
      old_websites.push({ ...website, prev: websitesObj[url.hostname] });
    else new_websites.push(website);
  });

  return { new_websites, old_websites };
};
